<template>
  <scroll-container>
    <MSA />
  </scroll-container>
</template>

<script>
import MSA from '../legal/MSA.vue'
import PageMixin from '../mixins/Page'

export default {
  mixins: [PageMixin],
  components: {
    MSA
  }
}
</script>

<template>
  <container class="p-4">
    <h3>Bolster Master Services Agreement</h3>

    <p>
      This Master Services Agreement (the “<strong>Agreement</strong>”) consists of the Bolster
      Terms of Service (the “Terms”) and any supplements. This Agreement is entered into as of the
      date of its execution (the “<strong>Effective Date</strong>”) by and between Bolster Built
      Inc. (“<strong>Bolster</strong>” or “<strong>We</strong>”), a Canadian corporation, and the
      undersigned (collectively referred to herein along with any subsidiaries or affiliates as
      “<strong>Client</strong>” or “<strong>You</strong>”) (each herein referred to individually as
      a “<strong>Party</strong>,” or collectively as the “<strong>Parties</strong>”).
    </p>

    <p>
      Client may purchase financial and other services (collectively the
      “<strong>Services</strong>”) from Bolster which are made available on our construction
      industry Software as a Service platform (the “<strong>Platform</strong>”) via our website:
      <a href="https://bolsterbuilt.com">bolsterbuilt.com</a> (the “<strong>Website</strong>”), our
      mobile application, pursuant to an Order or through other means. Those services will be
      delivered in accordance with the Terms and any applicable supplemental terms (the “<strong
        >Supplemental Terms</strong
      >”).
    </p>

    <p>
      THE TERMS CONTAINS IMPORTANT LIMITATIONS ON REPRESENTATIONS, WARRANTIES, CONDITIONS, REMEDIES
      AND LIABILITIES THAT ARE APPLICABLE TO THE SERVICES SO YOU SHOULD READ THEM CAREFULLY BEFORE
      USING THE SERVICES. EITHER BY ACCESSING THE SERVICES, REGISTERING AN ACCOUNT, CLICKING A BOX
      INDICATING YOUR ACCEPTANCE OR BY EXECUTING AN ORDER FORM THAT REFERENCES THIS AGREEMENT, YOU
      AGREE TO THE TERMS HEREOF. IF YOU ARE AN AGENT OR EMPLOYEE OF AN ENTITY YOU REPRESENT AND
      WARRANT THAT (I) THE INDIVIDUAL ACCEPTING THIS AGREEMENT IS AUTHORIZED TO ACCEPT THIS
      AGREEMENT ON SUCH ENTITY'S BEHALF AND TO BIND SUCH ENTITY, AND (II) SUCH ENTITY HAS FULL
      POWER, CORPORATE OR OTHERWISE, TO ENTER INTO THIS AGREEMENT AND PERFORM ITS OBLIGATIONS
      HEREUNDER. IF YOU DO NOT ACCEPT THESE TERMS, THEN DO NOT USE THE SERVICES.
    </p>

    <h4>Supplement 1 – Definitions</h4>

    <p>
      <strong>"Affiliate"</strong> means any entity that directly or indirectly controls, is
      controlled by, or is under common control with the subject entity.
      <strong>"Control,"</strong> for purposes of this definition, means direct or indirect
      ownership or control of more than 50% of the voting interests of the subject entity.
    </p>
    <p>
      <strong>"Agreement"</strong> means the Master Services Agreement including the Terms and any
      supplements and, where applicable, any Order.
    </p>
    <p>
      <strong>“Applicable Law”</strong> means all applicable state, federal, provincial and local
      laws, rules and regulations, including without limitation, the Bank Secrecy Act, the
      implementing regulations issued by the U.S. Treasury’s Office of Foreign Assets Control (OFAC)
      and the Federal Trade Commission, as well as any and all other federal and state anti-money
      laundering laws and regulations.
    </p>
    <p>
      <strong>"Contractor"</strong> means a construction service provider who contracts directly
      with Purchasers.
    </p>
    <p>
      <strong>"Purchaser"</strong> means an individual who contracts, or is seeking to contract,
      with a Contractor for receipt of construction services.
    </p>
    <p>
      <strong>"Documentation"</strong> means the applicable Service’s documentation and its usage
      guides and policies, as updated from time to time, accessible via bolsterbuilt.com or our App.
    </p>
    <p>
      <strong>"Fees"</strong> means the fees payable for any Services that are provided subject to
      payment of applicable fees.
    </p>
    <p>
      <strong>"Marketplace"</strong> or <strong>"Marketplace Service"</strong> means the marketplace
      component of the Service where Users may enter into agreements directly with each other, as
      facilitated by the Service.
    </p>
    <p>
      <strong>“Network Rules”</strong> are the rules, regulations, releases, interpretations and
      other requirements (whether contractual or otherwise) imposed or adopted by any Network.
    </p>
    <p>
      <strong>"Order"</strong> means an ordering document or online order specifying the Services to
      be provided hereunder that is entered into between You and Bolster including any addenda and
      supplements thereto.
    </p>
    <p>
      <strong>"Services"</strong> means the products and services of the Platform that are used by a
      User, whether through simply accessing the Platform or as subscribed for through an Order.
    </p>
    <p>
      <strong>"Subcontractor"</strong> means a construction service provider who is, or who may be,
      subcontracted by a Contractor.
    </p>
    <p>
      <strong>"Supplier"</strong> means a supplier of physical goods to Contractors and
      Subcontractors.
    </p>
    <p><strong>“Terms”</strong> means the Bolster Terms of Service.</p>
    <p>
      <strong>"Third Party Application"</strong> means a Web-based, mobile, offline or other
      software application functionality that interoperates with a Service, that is provided by You
      or a third party, including without limitation Salesforce.com.
    </p>
    <p>
      <strong>"User"</strong> means in the case of an individual accepting this Agreement on behalf
      of a company or other legal entity, an individual who is authorized by You to use a Service,
      for whom You have purchased a subscription (or in the case of any Services provided by Bolster
      without charge, for whom a Service has been provisioned), and to whom You (or, when
      applicable, Bolster at Your request) has supplied a user identification and password (for
      Services utilizing authentication). Users may include, for example, employees, consultants,
      contractors and agents of You, and third parties with which You transact business.
    </p>
    <p>
      <strong>"Your Data"</strong> means electronic data and information submitted by or for You to
      the Services.
    </p>

    <h4>Supplement 2 – Fees and Payment Terms</h4>

    <p>
      <strong>1. Subscription Fees.</strong> Access to the Bolster Platform is provided on a
      Software-as-a-Service basis and requires all Users to have a valid subscription. We may charge
      Users a Fee to access and use parts of the Services that are subject to Paid Subscriptions.
    </p>

    <p>
      <strong>2. Additional one-time or Add-on Fees.</strong> Users may purchase one-time services
      or add-on additional services either separately to or as part of their Paid Subscription. Such
      additional fees will either be indicated at the time of incurrence or, in respect of Paid
      Subscriptions, will be as set forth in the applicable Order. Without limiting the foregoing,
      Bolster may also automatically charge Users add-ons or one-time service fees as Users exceed
      their subscription limits for Paid Subscriptions. The fees for one-time services or add-ons
      will be determined at the time of purchase of such services.
    </p>

    <p>
      <strong>3. Renewals.</strong> Paid Subscriptions automatically renew at the end of the
      subscription term indicated in the applicable Order unless cancelled by the User at least 30
      days’ prior to the renewal date. Subscription fees are subject to change to the subscription
      cost at the time of renewal.
    </p>

    <p>
      <strong>4. Upgrades.</strong> Any upgrade to the original Paid Subscription will result in a
      new subscription fee, prorated based on the date subscription was upgraded. Bolster may
      automatically upgrade User subscriptions based on usage of services to prevent disruption of
      services to the User. Bolster will provide notice at least 5 days prior to changing the
      subscription.
    </p>

    <p>
      <strong>5. Downgrades.</strong> Any downgrade will result in a new subscription fee charged
      during the next billing cycle. There is no prorating for downgrades during the term of the
      subscription.
    </p>

    <p>
      <strong>6. Cancellations.</strong> Users may cancel their Paid Subscription which will take
      effect during their next billing cycle. Bolster does not provide refunds for any type of
      cancellation.
    </p>

    <p>
      <strong>7. Fees for Escrow and Payment Services.</strong> Bolster charges transaction fees for
      use of certain escrow and payment services. Fees are set out in the ordering document at the
      time you register for such services ("<strong>Transaction Fees</strong>"). The Transaction
      Fees will be calculated as a percentage of the gross total value of the transaction, which
      will be quoted to User on the Bolster Platform at the time of the transaction. Bolster
      reserves the right to change any fees at our sole discretion by providing 30 days’ notice.
    </p>

    <p>
      <strong>8. Payment Method.</strong> In order to use the Services, Bolster requires Users to
      provide valid banking information or other acceptable payment information to register
      (collectively known as "<strong>Billing Information</strong>"), before entering into any
      Projects. Users hereby agree that all payments will be processed by Bolster’s third-party
      payment processors (each a "<strong>Payment Processor</strong>") and that all such payments
      will be governed by Payment Processor’s terms and conditions and privacy policy available at:
      <a href="app.bolsterbuilt.com/pub/legal">app.bolsterbuilt.com/pub/legal</a> or as otherwise
      published by the Payment Processor. Users shall advise Bolster within 10 business days if
      their Billing Information changes due to loss, theft, cancellation, expiry, or otherwise, and
      Users shall be liable for any failure to pay Fees caused by out-of-date billing information or
      other fees incurred by Bolster due to failure to pay Fees when due or wrongfully disputing any
      Fee payment. Bolster reserves the right to suspend access to the Service if any Fees are
      outstanding.
    </p>

    <p>
      <strong>9. Taxes.</strong> The Subscription Fees and Transaction Fees may be subject to
      applicable sales taxes which will be determined by Bolster and added to the respective fees.
      Bolster reserves the right to deduct the Fees and any applicable taxes from the User's
      account.
    </p>

    <p>
      <strong>10. Contractor Taxes.</strong> Each contractor or subcontractor, as applicable, hereby
      agrees and acknowledges that it is exclusively responsible for determining the amount of all
      applicable taxes to be charged on all job fees and is exclusively responsible for posting such
      fees, inclusive of all applicable taxes and any required tax identification numbers, on the
      invoices generated by the Bolster Platform. For greater certainty, Users hereby agree and
      acknowledge that Bolster is not responsible for calculating, reporting, remitting, and
      withholding any applicable federal, state, provincial, goods, services, value-added,
      municipal, or other taxes associated with User’s job fees. Each User hereby represents and
      warrants that all tax information it provides in connection with its use of the Bolster
      Platform is accurate. Notwithstanding the foregoing, each User remains solely responsible for
      ensuring all applicable taxes are properly calculated.
    </p>

    <p>
      <strong>11. Purchaser Taxes.</strong> Bolster is not responsible for calculating, reporting,
      remitting, and withholding any applicable federal, state, provincial, goods, services,
      value-added, municipal, or other taxes associated with a Purchaser’s engagement of a
      contractor or sub-contractor, as applicable.
    </p>

    <p><strong>12. Refunds.</strong> Bolster does not offer refunds.</p>

    <h4>Supplement 3 – Marketplace Supplemental Terms</h4>

    <p>
      <strong>1. Project.</strong> Users may use the Marketplace Service to negotiate and enter into
      project work orders with other Users ("<strong>Projects</strong>"). Projects may include scope
      of work, maximum hours, payments terms, and payment schedule, and may be based on hourly
      rates, fixed fees, or such other basis as may be agreed to between the applicable Users.
    </p>

    <p>
      <strong>2. Marketplace terms.</strong> Bolster’s Marketplace is only a platform for connecting
      Users. Users contract directly with the other User or third-party they hire and are free to
      negotiate the terms of the Project. Nothing in our involvement in the Marketplace and payment
      process makes us responsible for the actions of a User. By using the Marketplace Services, you
      understand and acknowledge that Bolster provides a marketplace that simply connects Users, and
      that all Users are independent contractors and not employees of Bolster. Users are solely
      responsible for setting the terms of any agreement between them through the Service, and
      subsequently complying with and enforcing any such agreement. Bolster is not responsible for
      any User’s conduct, nor will we be responsible for or liable in any way for any act or
      omissions of any User or third party’s or for any damage however caused in connection with or
      in relation to any and all use of the Services. Bolster does not endorse any Users. In
      addition, although we require Users to provide accurate information, we do not attempt to
      confirm, and do not confirm, any User’s purported identity or other information provided by
      such User. You are responsible for determining the identity and suitability of others who you
      contact via the Services. We will not be responsible for any damage or harm resulting from
      your interactions with other Users.
    </p>

    <p>
      <strong>3. Inter-User Agreements.</strong> IN THE EVENT THAT ANY USERS WISH TO ENTER INTO AN
      AGREEMENT REGARDING A PROJECT, ANY SUCH AGREEMENT AS BETWEEN USERS SHALL BE A SEPARATE
      AGREEMENT AS BETWEEN OWNER AND CONTRACTOR AND SHALL NOT INCLUDE Bolster AS A PARTY. Bolster
      CANNOT AND DOES NOT CONTROL THE RELATIONSHIP BETWEEN USERS, OR THE SERVICES PROVIDED BY ANY
      USER. Bolster IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND
      ALL WORKS OR OTHER ACTIONS OR OMISSIONS OF ANY USER. ACCORDINGLY, ANY AGREEMENT IN RESPECT OF
      ANY PROJECT BETWEEN ANY USERS WILL BE MADE AT THE USERS OWN RISK. By using the Services, you
      agree that any legal remedy or liability that you seek to obtain for actions or omissions of
      other Users or other third parties will be limited to a claim against the particular Users or
      other third parties who caused you harm and you agree not to attempt to impose liability on,
      or seek any legal or financial remedy from Bolster with respect to such actions or omissions.
    </p>

    <p>
      <strong>4. Disclaimer.</strong> We have no control over the conduct of our Users or the truth
      or accuracy of the information that Users post on Bolster. We cannot guarantee the true
      identity of any individual. You are responsible for determining the identity and suitability
      of any person or entity you may contact by means of the Platform. All Users are independent
      third parties. We do not endorse any persons who use or register for our Services.
    </p>

    <p>
      <strong>5. Insurance.</strong> Each User, at its sole discretion and expense, will maintain
      the appropriate insurance in accordance with industry standards and as required by law.
    </p>

    <p>
      <strong>6. Regulatory Compliance.</strong> As part of the payment services in respect of any
      Project, Bolster may allow Users to deposit funds with our bank partners and pay out other
      Users. Accordingly, Bolster is governed by, and committed to full compliance with, Applicable
      Law, including without limitation laws regarding Anti-Money Laundering
      ("<strong>AML</strong>"). Users may be required to provide photo identification or other
      personal information to verify their identity in order to process a transaction using the
      Services. Bolster reserves the right to limit the amount of deposits and payouts through the
      Services, or otherwise restrict use of the Services, in its sole discretion, in order to
      comply with its obligations under Applicable Law.
    </p>

    <h4>Supplement 4 – Escrow Services (Pay-out) Supplemental Terms</h4>

    <p>
      <strong>1. Project Deposit.</strong> Any User using the Platform to purchase services from
      another User under a Project may fund their Account by making a deposit ("<strong
        >Project Deposit</strong
      >"). A minimum contract amount may be required before any other User begins performing the
      services agreed between the Users. Once the Contractor indicates through the Platform that
      work on the Project has begun, the Purchaser will not be able to withdraw funds from the
      Project Deposit. Purchaser Deposits may also be subject to additional terms as may be imposed
      by the applicable payment processor such as Adyen.
    </p>

    <p>
      <strong>2. Contractor Pay-out.</strong> Upon completion of the job under a Project, the
      Contractor shall use the Platform to notify the Purchaser. Unless otherwise specified in a
      Project, the Purchaser shall either (a) confirm the work is completed, in which case any
      payment owing to the Contractor will be debited from the purchaser’s Account and paid the
      Contractor; or (b) notify the Contractor that the work is not complete. If the work is marked
      by the Purchase as "incomplete" or the Purchaser does not respond, the Purchaser and
      Contractor shall cooperate in good faith to resolve the dispute. Contractor and the Purchaser
      acknowledge that any payment may be delayed by up to 5 days for payment processing.
    </p>

    <p>
      <strong>3. Mediation.</strong> In the event of a dispute between Users regarding any payment
      from a Project Deposit that cannot be resolved directly between the Users, Users shall seek to
      resolve such dispute through mediation, which may be facilitated by Bolster. In the event that
      the parties inform Bolster that they have not reached a settlement through mediation, the
      remaining balance of the Project Deposit held by Bolster shall be refunded to the Purchaser
      (less any transaction Fees owed to Bolster and any amounts already approved to be paid by
      Purchaser).
    </p>

    <p>
      <strong>4. Acceptance.</strong> The purchaser agrees that acceptance of the work through the
      Platform constitutes legal and binding acceptance. Once the Purchaser marks work as being
      complete, the Purchaser agrees that Bolster may release all project Deposit amounts, waives
      all claims against Bolster in respect of such release, and shall indemnify and hold harmless
      Bolster in connection with the Project.
    </p>

    <h4>Supplement 5 – Franchise Supplemental Terms</h4>

    <p>
      <em
        >Where indicated in an Order, certain functionality within the Service may be subscribed for
        by Users as either a "<strong>Franchisor</strong>" or a "<strong>Franchisee</strong>". In
        such event, these Supplemental Terms shall apply.</em
      >
    </p>

    <h5>1. Franchisor Terms</h5>
    <p>
      1.1. Franchisor shall be granted the right to access and hold a copy of all of Franchisor’s
      Franchisee data held by such Franchisees through the Service ("<strong>Franchisee Data</strong
      >");
    </p>
    <p>
      1.2. Fees for each of Franchisor’s subscription to the Services, and any Fees payable by
      Franchisor’s Franchisees, shall be specified in the Order as between Franchisor and Bolster.
      All Franchisee Fees shall be payable directly by Franchisees to Bolster.
    </p>
    <p>
      1.3. Franchisee subscription terms shall be pro-rated such that they be contiguous with the
      term of the applicable franchise agreement as between Franchisor and Franchisee (unless
      subscribed for a shorter period under the applicable Order). In addition to any other
      termination rights under these Supplemental Terms, Franchisee subscription agreements with
      Bolster shall terminate on Franchisor giving Bolster notice that the applicable franchise
      agreement as between Franchisor and the Franchisor’s Franchisee have terminated.
    </p>
    <p>
      1.4. All Franchisee Data shall continue to be made available to Franchisor notwithstanding the
      termination such Franchisee agreement, and may be transferred to a replacement Franchisee
      account where applicable. Franchisee may also maintain a copy of such Franchisee Data.
    </p>

    <h5>2. Franchisee Terms</h5>
    <p>
      2.1. Franchisee agrees that the applicable Franchisor named in the Order shall be granted the
      right to access and hold a copy of all of Franchisees Data;
    </p>
    <p>
      2.2. Fees payable by Franchisee shall be specified in the Order and shall be payable directly
      by Franchisees to Bolster.
    </p>
    <p>
      2.3. Franchisee subscription terms shall be pro-rated such that they be contiguous with the
      term of the applicable franchise agreement as between Franchisor and Franchisee (unless
      subscribed for a shorter period under the applicable Order). In addition to any other
      termination rights under these Supplemental Terms, Franchisee subscription agreements with
      Bolster shall terminate on Franchisor giving Bolster notice that the applicable franchise
      agreement as between Franchisor and the Franchisor’s Franchisee have terminated.
    </p>
    <p>
      2.4. All Franchisee Data shall continue to be made available to Franchisor notwithstanding the
      termination such Franchisee agreement, and may be transferred to a replacement Franchisee
      account where applicable. Franchisee may also maintain a copy of such Franchisee Data.
    </p>

    <h4>Supplement 6 – Payment Processing Supplemental Terms for Sub-merchants</h4>

    <p>
      These Payment Processing Supplemental Terms for Sub-merchants set forth the terms and
      conditions that govern the payment processing services to be provided by Bolster to its
      registered sub-merchants (each a “Sub-merchant”). Such payment processing services are
      provided by Adyen N.V. and/or, where relevant, its local affiliates and/or branches,
      successors, and assignees (“<strong>Processor</strong>”) and the relevant bank which is a
      member of VISA, MasterCard and/or Discover, as applicable, that provides sponsorship services
      in connection with these Supplemental Terms (the “<strong>Member Bank</strong>”). Bolster is
      required to enter into these Supplemental Terms with its customers, who are Sub-merchants for
      purposes of these Supplemental Terms. Sub-merchant has registered to receive Services more
      fully described on bolsterbuilt.com, in accordance with the Agreement. Sub-merchant has
      applied for an Account for the Services described herein, and by its acceptance of the
      application, Bolster agrees to provide Sub-merchant with certain payment processing services
      in accordance with the terms of the Agreement and these Supplemental Terms to facilitate
      Sub-merchant’s acceptance of credit and debit card payments for goods and services. In
      consideration of Sub-merchant’s receipt of credit or debit card funded payments, and
      participation in programs affiliated with MasterCard International Inc.
      (“<strong>MasterCard”</strong>), VISA U.S.A. Inc (“<strong>VISA</strong>”), Discover Financial
      Services, LLC (“<strong>Discover</strong>”), (collectively, “<strong>Networks</strong>”),
      Sub-merchant is required to comply with the Network Rules as they pertain to applicable credit
      and debit card payments.
    </p>

    <h5>1. The Services</h5>
    <p>
      Bolster is a Payment Facilitator as provided for in the Network Rules. Bolster has arranged
      for Processor to acquire, process and settle payment for transactions initiated by
      Sub-merchant’s Payors. Such acquiring, processing, and settlement shall be made in accordance
      with the Network Rules using the channels set forth in the Account Application, which include:
      credit/debit card and EFT (electronic funds transfer) payments through the Website,
      (collectively “<strong>Payments</strong>”). Subject to processing delays and risk holds,
      Bolster has made arrangements as a Payment Facilitator to cause Payments to be made to
      Sub-merchant. Processor will periodically transfer Payments to Sub-merchant’s Account based
      upon instructions provided by Bolster.
    </p>

    <h5>2. Authorization</h5>
    <p>
      Sub-merchant hereby authorizes Bolster to facilitate the debiting and crediting of its Account
      for purposes of depositing Payments to Sub-merchant’s Account, debiting from Sub-merchant’s
      Account any chargebacks, refunds, or reversals, and debiting any fees or charges owed to
      Bolster. Such authorization shall remain in full force and effect until thirty (30) days after
      Bolster receives written notification from Sub-Merchant of termination of the ACH
      authorization, by email to
      <a href="mailto:support@bolsterbuilt.com">support@bolsterbuilt.com</a>. We reserve the right
      to terminate or suspend the services at any time that you fail to provide an active Account
      with debit authorization. Bolster shall in no event be liable for any damages directly or
      indirectly resulting from incorrect Account information.
    </p>

    <h5>3. Sub-merchant Responsibilities.</h5>
    <p>
      <strong>3.1. Sub-merchant</strong> agrees to comply, and to cause third parties acting as
      Sub-merchant’s agent (“<strong>Agents</strong>”) to comply, with the Network Rules, the
      Payment Card Industry Data Security Standards (“<strong>PCI-DSS</strong>”), the VISA
      Cardholder Information Security Program, the MasterCard Site Data Protection Program, and any
      other program or requirement that may be published and/or mandated by the Networks.
      Sub-merchant may review the VISA, MasterCard, and Discover websites for a copy of the Visa,
      MasterCard and Discover Network Rules. As of the date of the Agreement, the websites are:
      <a href="http://usa.visa.com/customers/">http://usa.visa.com/customers/</a> and
      <a href="http://www.mastercard.com/us/Sub-merchant/"
        >http://www.mastercard.com/us/Sub-merchant/</a
      >
      and
      <a href="http://www.discovernetwork.com/customers/"
        >http://www.discovernetwork.com/customers/</a
      >. Sub-merchant will comply with the Card acceptance and website requirements set forth in the
      Network Rules. Without limiting the foregoing, Sub-merchant agrees that it will fully comply
      with any and all anti-money laundering laws and regulations, including but not limited to the
      Bank Secrecy Act, the US Treasury’s Office of Foreign Assets control (OFAC) and the Federal
      Trade Commission. For purposes of this section, Agents include, but are not limited to,
      Sub-merchant’s software providers and/or equipment providers.
    </p>
    <p>
      <strong>3.2. Sub-merchant</strong> also agrees, upon request, to execute a direct processing
      agreement with Processor, in the form provided by Processor, as a supplement to these
      Supplemental Terms, and will provide Bolster with a copy of such agreement upon request.
    </p>
    <p>
      <strong>3.3. Sub-merchant</strong> will permit Bolster to perform risk monitoring functions as
      required by the Network Rules.
    </p>
    <p>
      <strong>3.4. Sub-merchant</strong> will notify Bolster immediately of any Payor disputes or
      other matters that require escalation to Processor and immediately forward any notices
      received by Sub-merchant concerning a disputed payment transaction.
    </p>
    <p>
      <strong>3.5. Sub-merchant</strong> will assure that only sales transactions produced as the
      direct result of bona fide sales to Payors for such identified products and/or services are
      completed and delivered to Bolster for processing.
    </p>
    <p>
      <strong>3.6. Sub-merchant</strong> will not present sales transactions for any purposes
      related to any illegal or prohibited activity, including but not limited to money-laundering
      or financing of terrorist activities.
    </p>

    <h5>4. Sub-merchant Prohibitions</h5>
    <p>
      <strong>4. Sub-merchant Prohibitions.</strong> Sub-merchant acknowledges and agrees that the
      prohibited actions described below (“<strong>Prohibited Actions</strong>”) are actions which
      may mislead, disadvantage, defraud or damage any, or all of, the following entities; (a) a
      Payor; (b) the issuing bank; (c) the settlement bank; (d) the Networks; (e) Bolster; (f)
      Processor; or (f) the Member Bank. Sub-merchant agrees that it must take all available steps
      and precautions to prevent fraud, theft, or misappropriation of Payor data. Sub-merchant
      agrees that it will not take any of the following <strong>Prohibited Actions</strong> and it
      will not permit a third party under its control to take Prohibited Actions in any situation
      where it has knowledge of such actions. Sub-merchant is deemed to be responsible for and to
      control the conduct of its employees, contractors, customers, and representatives.
    </p>
    <p>
      <strong>4.1. Sale Transactions.</strong> Sub-merchant will not submit any sales transaction to
      Processor: (a) that adds any surcharge to the transaction, except to the extent authorized by
      the Network Rules or Applicable Law; (b) that adds any tax to the transaction, unless
      Applicable Law expressly allows for the customer to impose a tax. Any tax amount, if allowed,
      must be included in the transaction amount and not collected separately; (c) that represents
      the refinancing or transfer of an existing Payor obligation; (d) that Sub-merchant knows or
      should have known to be fraudulent or not authorized by the Payor, or that it knows or should
      have known to be authorized by a customer colluding with Sub-merchant for a fraudulent
      purpose; (e) until after the services are performed, and/or Sub-merchant has completed the
      transaction, unless Sub-merchant has obtained Payor consent for a recurring transaction
      specifying the frequency of the recurring charge and the duration of time during which such
      charges may be made; (f) where a valid authorization was required but not obtained; (g) where
      multiple authorizations for amounts less than the total sale amount have been obtained; (h)
      which results in a disbursement of cash or cash equivalent to a Payor; (i) that establishes a
      maximum dollar sale transaction amount, except to the extent authorized by the Network Rules.
    </p>
    <p>
      <strong>4.2. Refund Transactions.</strong> Sub-merchant will not submit any refund transaction
      to Processor: (a) that does not correlate to an original sales transaction from the
      Cardholder; (b) that exceeds the amount shown as the total on the original sale transaction;
      (c) more than three (3) business days following either: (i) a regulatory requirement granting
      a Payor’s right to a refund; or (ii) a non-disputed Payor request.
    </p>
    <p>
      <strong>4.3. Other Prohibited Activities.</strong> Sub-merchant will not: (a) use any Payor
      data or other transaction data for any purpose not authorized by these Supplemental Terms; (b)
      disclose any Payor data or other transaction data to any entity except for necessary
      disclosures to affected Payors, and through Processor to affected Network entities (c) provide
      to Processor or Bolster any inaccurate, incomplete, or misleading information; (d) fail to
      provide Bolster with timely notification of events that have caused or could cause material
      changes in the Sub-merchant’s ability to fulfill its obligations under these Supplemental
      Terms, including but not limited to (i) adverse changes in Sub-merchant’s financial health;
      (ii) adverse changes in Sub-merchant’s business conditions or environment; or (iii) actions by
      governmental or non-governmental agencies; (e) transfer or attempt to transfer its financial
      liability by asking or requiring Payors to waive their dispute rights; (f) submit transactions
      on behalf of another entity that the Networks would consider a sub-ISO, Payment Service
      Provider, Bolster, or other third party payment provider; (g) submit transactions for entities
      that do not have their principal places of business in the United States or Canada.
    </p>

    <h5>5. Losses</h5>
    <p>
      <strong>5. Losses.</strong> Sub-merchant acknowledges and agrees that Sub-merchant is solely
      responsible for all losses of any kind, including but not limited to (a) credit, chargeback,
      and fraud losses, and Network assessments that we may incur in connection with Sub-merchant
      transactions; and (b) any fines, fees, assessments, or other costs or liability we may incur
      as a result of Sub-merchant’s violation of Applicable Law, Network Rules or these Supplemental
      Terms. Chargebacks can be received for a number of reasons. The following are some of the most
      common reasons for chargebacks: (a) a refund is not issued to a customer upon the return or
      non-delivery of goods or services; (b) an authorization/approval code was required and not
      obtained; (c) the transaction was allegedly fraudulent; (d) the customer disputes the sale or
      the signature on the sale documentation, or claims that the sale is subject to a set-off,
      defense or counterclaim; or (e) the customer refuses to make payment for a sale because in the
      customer's good faith opinion, a claim or complaint has not been resolved, or has been
      resolved but in an unsatisfactory manner. Sub-merchant acknowledges and accepts that
      Sub-merchant is solely liable for chargebacks that occur in connection with Sub-merchant
      transactions.
    </p>

    <h5>6. Parties; Entire Agreement</h5>
    <p>
      <strong>6. Parties; Entire Agreement.</strong> These Supplemental Terms constitute the
      agreement required between Bolster and its sub-merchants. In addition, the Sub-merchant may be
      required to enter into a direct processing agreement with Processor (“<strong
        >Direct Processing Agreement</strong
      >”) if it processes transactions in excess of a certain dollar amount as required by the
      Network Rules. These Supplemental Terms and the Direct Processing Agreement, if applicable,
      shall constitute the entire agreement between the parties concerning the subject matter
      hereof. These Supplemental Terms shall not be superceded or replaced by the Direct Processing
      Agreement. In the event of a conflict between These Supplemental Terms and the Direct
      Processing Agreement, the terms of the Direct Processing Agreement shall control.
    </p>

    <h5>7. Investigations</h5>
    <p>
      <strong>7. Investigations.</strong> Sub-merchant will promptly notify Bolster in the event
      Sub-merchant becomes aware of any unusual or suspicious activity regarding its customers and
      will cooperate with Processor, Bolster, Member Bank and the Networks, as applicable, in
      connection with any investigation of its customers’ background or activity.
    </p>

    <h5>8. Reserve Account; Right of Setoff</h5>
    <p>
      <strong>8. Reserve Account; Right of Setoff.</strong> Bolster reserves the right to require a
      reserve of funds (the “<strong>Reserve Account</strong>”) in an amount satisfactory to Bolster
      to secure Sub-merchant’s obligations to Bolster hereunder. Upon notice to Sub-merchant,
      Bolster may make one or more deductions or offsets to any payments otherwise due to
      Sub-merchant to fund the Reserve Account in an amount satisfactory to Bolster based upon a
      reasonably anticipated risk of loss to Bolster. To secure Bolster's obligations to Processor
      and Member Bank on Sub-merchant’s behalf, Sub-merchant grants to Processor, Member Bank and
      Bolster a lien and security interest in and to (i) any such Reserve Account, and (ii) any of
      Sub-merchant’s funds pertaining to the transactions contemplated herein now or hereafter in
      Processor’s or Member Bank’s possession, whether now or hereafter due or to become due to
      Sub-merchant. In addition to any rights now or hereafter granted under Applicable Laws and not
      by way of limitation of any such rights, Processor and Member Bank are hereby authorized by
      Sub-merchant at any time and from time to time, without notice or demand to Sub-merchant or to
      any other person (and such notice and demand being hereby expressly waived), to set off,
      recoup and to appropriate and to apply any and all funds held in a Reserve Account established
      hereunder and held by Bolster against and on account of obligations owed to Processor or
      Member Bank by Bolster on Sub-merchant’s behalf, whether such obligations are liquidated,
      unliquidated, fixed, contingent, matured or unmatured. Sub-merchant agrees to duly execute and
      deliver to Processor or Member Bank, as applicable, such instruments and documents as they may
      reasonably request to perfect and confirm the lien or security interest set forth in these
      Supplemental Terms. The right of setoff shall be deemed to have been exercised immediately
      upon the occurrence of default hereunder without any action by Bolster or notation in
      Bolster’s records, although Bolster may enter such set off on its books and records at a later
      time.
    </p>

    <h5>9. Audits</h5>
    <p>
      <strong>9. Audits.</strong> Sub-merchant authorizes Bolster, directly or through affiliates or
      contractors, to make any investigation of Sub-merchant finances, activities, and operations as
      we deem necessary and appropriate. Upon request, Sub-merchant will promptly provide us with
      any necessary permissions or consents, financial statements or other information reasonably
      requested by us to perform credit risk, security, qualification, and other reviews related to
      the provision of the Services, transactions submitted, fulfillment of obligations to us, or
      Sub-merchant’s financial condition. In addition, Sub-merchant authorizes us (i) to share such
      information with each other; and (ii) our respective Affiliates and service providers as
      necessary to provide the Services. At any reasonable time upon reasonable notice to
      Sub-merchant, Sub-merchant shall allow auditors, including the auditors of Bolster, any
      Network or any third party designated by Bolster, Processor or the applicable Network, to
      review the files held and the procedures followed by Sub-merchant at any or all of
      Sub-merchant’s offices or places of business. Any such audit shall be at Bolster’s expense,
      unless it is required by Processor or by a Network. Sub-merchant will assist such auditors as
      may be necessary for them to complete their audit, and will cooperate where necessary and
      appropriate. In the event that a third-party audit is requested by a Network, Member Bank or
      regulatory agency, and/or required by the Network Rules or Applicable Law, Bolster may, at its
      option, and at Sub-merchant’s sole expense, either retain a third party to perform the audit,
      or require that Sub-merchant directly retain a specific third party auditor. If Bolster
      requires that Sub-merchant directly retain the auditor, Sub-merchant shall arrange immediately
      for such audit to be performed, and will provide Bolster and the Networks with a copy of any
      final audit report.
    </p>

    <h5>10. Card Information</h5>
    <p>
      <strong>10. Card Information.</strong> Sub-merchant represents to Bolster that it does not
      have access to Card information (such as the Cardholder’s account number, expiration date, and
      CVV2) and will not request access to such Card information from Bolster. In the event that
      Sub-merchant receives such Card or other personal information in connection with the
      processing services provided under these Supplemental Terms, Sub-merchant agrees that it will
      not use it for any fraudulent purpose or in violation of any Network Rules, including but not
      limited to PCI DSS or Applicable Laws. If at any time Sub-merchant believes that personal
      information has been compromised, Sub-merchant must notify Bolster promptly and assist in
      providing notification to the proper parties. Sub-merchant must ensure compliance by itself
      and any third party service provider utilized by Sub-merchant, with all security standards and
      guidelines that are applicable to Sub-merchant and published from time to time, including
      without limitation those published by Visa, MasterCard or any other Network, and including,
      without limitation, the Visa U.S.A. Cardholder Information Security Program, the MasterCard
      Site Data Protection, and (where applicable), the PCI Security Standards Council, Visa, and
      MasterCard PA-DSS. Bolster will not be responsible for unauthorized use or access to personal
      information or financial data by Sub-merchant, sub-merchant’s employees, or any other party
      associated with Sub-merchant, except to the extent such use or access is due to Bolster's
      fault or negligence. If any Network requires an audit of Sub-merchant due to a data security
      compromise event or suspected event, Sub-merchant agrees to cooperate with such audit.
      Sub-merchant may not use any Card information other than for the sole purpose of completing
      the transaction authorized for which the information was provided to Sub-merchant, or as
      specifically allowed by Network Rules, or as required by Applicable Law. Bolster may use any
      and all information gathered in the performance of the Services or the operation of the
      Website in accordance with its Privacy Policy. In addition, Sub-merchant agrees that Bolster
      may use such information for any lawful purpose including marketing and deriving statistics
      regarding the Services.
    </p>

    <h5>11. Modification of these Supplemental Terms or the Services</h5>
    <p>
      <strong>11. Modification of these Supplemental Terms or the Services.</strong> Bolster may
      from time to time without prior notice amend or modify these Supplemental Terms or the
      Services, including without limitation a change to the pricing, terms or products offered;
      provided, however, that Bolster will not modify the Services in a manner that would, in its
      sole discretion, significantly adversely affect Sub-merchant’s use thereof, without providing
      at least thirty days’ prior notice to Sub-merchant of any such modification. Such notice may
      be made by means of email or a posting on the Website. Sub-merchant’s continued use of the
      Services following notification of any change or amendment to these Supplemental Terms or the
      Services shall be evidence of its consent and agreement to the modification and/or amendment.
      Posting notice of any modification or amendment on the Website shall be deemed adequate
      notification.
    </p>

    <h5>12. Fees</h5>
    <p>
      <strong>12. Fees.</strong> Sub-merchant shall pay the fees as described in and in the amounts
      set forth in Supplement 2 – Fees and Payment Terms. Sub-merchant is responsible for disclosing
      all Payor-paid fees to Payors. If a dispute arises related to non-disclosure of Payor-paid
      fees, Sub-merchant shall be liable to Bolster for the full transaction amount including fees
      or charges for facilitating the payment of amounts due to Sub-merchant, as Bolster may
      determine in its sole discretion. Bolster may grant or deny to Users the ability to use the
      Bolster Services for any reason in its sole discretion. Bolster reserves the right to modify
      and amend all fees payable for the Services upon ten days’ notice to Sub-merchant.
    </p>

    <h5>13. Tax Reporting</h5>
    <p>
      <strong>13. Tax Reporting.</strong> Bolster is required to report to the IRS, on an annual
      basis, Sub-merchant’s annual gross processing volume. Sub-merchant is responsible for
      providing us with a matching taxpayer identification number and legal name on file with the
      IRS and shall be liable to us for any amounts incurred arising out of incorrect information or
      the failure to provide such information. Bolster will provide Sub-merchant a copy of
      Sub-merchant’s 1099-K by January 31st of each year for the previous calendar year’s
      transaction activity.
    </p>

    <h5>14. Notification; Disputes</h5>
    <p>
      14.1. Sub-merchant agrees to notify Bolster by a written communication with Bolster of any
      alleged breach by Processor of these Supplemental Terms, which notice will specifically detail
      such alleged breach, within thirty (30) days of the date on which the alleged breach first
      occurred. Failure to so provide notice shall be deemed an acceptance by Sub-merchant and a
      waiver of any and all rights to dispute such breach. Sub-merchant hereby authorizes Bolster to
      assert any such claim against Processor on its behalf, and to take all steps deemed necessary
      or appropriate in connection with such claim.
    </p>
    <p>
      14.2. Sub-merchant is liable for all acts, omissions, Payor disputes, and other Payor customer
      service-related issues. Sub-merchant acknowledges that Processor may refuse to process
      transactions for it in Processor’s reasonable discretion, and Sub-merchant agrees that
      Sub-merchant, and not Bolster, shall be responsible for resolving any issues, problems, or
      disputes with its customers.
    </p>
    <p>
      14.3. Sub-merchant acknowledges and agrees that: (i) Sub-merchant’s receipt of Payments are
      transactions between Sub-merchant and the relevant Payor and not with Bolster or any of
      Bolster’s affiliates; (ii) Bolster is a Payment Facilitator for Sub-merchant and is not a
      party to any transaction; and (iii) funds processed by Processor or its service providers
      (including any bank service providers) in connection with the processing of Payments are not
      deposit obligations and are not insured for Sub-merchant’s benefit by any governmental agency.
    </p>

    <h4>Supplement 7 – Supplemental Terms for Canada</h4>

    <p>
      Link to the:
      <a
        href="https://www.canada.ca/en/financial-consumer-agency/services/industry/laws-regulations/credit-debit-code-conduct.html"
        >Code of Conduct for the Credit and Debit Card Industry in Canada</a
      >
    </p>

    <h5>Information Summary and Fee Disclosure Box</h5>
    <h6>Information Summary Box</h6>
    <p>
      This Information Summary Box “ISB” reflects the standard terms of the template agreement
      within which we provided you this link (“Agreement”). “You,” as used herein, means the entity
      or individual who is a party to such Agreement. You may have negotiated custom terms in your
      specific Agreement. Unless explicitly outlined herein, the terms of this ISB do not replace or
      supersede the terms of your relevant Agreement. Any capitalized terms not defined herein shall
      have the meaning assigned to them in your relevant Agreement.
    </p>

    <h5>Date of Contract</h5>
    <p>
      Unless you negotiated otherwise, the Agreement starts upon the Effective Date and continues
      indefinitely until terminated for convenience by either party upon thirty days written notice
      to the other party.
    </p>

    <h5>Acquirer</h5>
    <p>
      Bolster, Inc.; Floor 19, 685 Centre St S, Calgary, AB T2G 2C7, Canada,
      <a href="mailto:support@bolsterbuilt.com">support@bolsterbuilt.com</a>
    </p>

    <h5>Cancellation of Contract and Applicable Penalties</h5>
    <p>
      Unless you negotiated otherwise, You may terminate the Agreement for convenience upon thirty
      days written notice to Acquirer. There are no early termination penalties for termination for
      convenience. However, termination does not alter your liability under the Agreement, including
      those for processed payments, related chargebacks, or other liabilities in connection with the
      Agreement. If there are negotiated custom terms or commercial commitments in your Agreement,
      You will be responsible for such terms or commitments. Regardless of what the contract states
      on the merchant’s right to terminate the Agreement, You may terminate the Agreement without
      penalty in the event of:
    </p>
    <ul>
      <li>
        A fee increase made unilaterally by Bolster, except one made in accordance with a
        pre-determined fee schedule in the contract;
      </li>
      <li>The introduction of a new fee by Bolster unilaterally; or</li>
      <li>A reduction in applicable interchange rates that is not fully passed on to You.</li>
    </ul>
    <p>
      To do so, You must exercise this right within 90 days of the date of notice of the above event
      by sending notice to the Acquirer to this effect or to the person responsible for such matters
      under the contract.
    </p>

    <h5>Complaint Handling Procedures</h5>
    <p>
      Your complaints should be submitted to
      <a href="mailto:support@bolsterbuilt.com">support@bolsterbuilt.com</a>.
    </p>

    <h5>Contactless Payments Acceptance</h5>
    <p>
      Contactless payments have been enabled at the point-of-sale for all payment networks
      applicable to the Agreement.
    </p>

    <h5>Transaction Return Policy</h5>
    <p>
      Refunds are charged as a transaction. In addition, a refund fee may be applicable as specified
      in Supplement 2 – Fees and Payment Terms.
    </p>

    <h5>Code of Conduct and Interac Regulations</h5>
    <p>
      The Code of Conduct for the Credit and Debit Card Industry is available at the FCAC's website.
    </p>

    <h5>Statements</h5>
    <p>
      Your transaction history and invoices will be available to you free of charge via our website:
      <a href="http://bolsterbuilt.com">bolsterbuilt.com</a>.
    </p>

    <h4>Fee Disclosure Box</h4>
    <p>
      Each rate disclosed below is the sum of the network's interchange rate + network assessment
      fee + per transaction processing fees based on a CA $100. Scheme rates are subject to change.
    </p>
    <p>
      This table is for illustrative purposes only and is not intended to capture all possible
      payment card types or processing methods. It is intended to present price information for the
      most common type of domestic card transactions and processing methods available to merchants.
    </p>
    <p>
      Rates may be lower based on custom or tiered pricing per transaction within your Agreement.
    </p>

    <!-- Table 1 - Fee Disclosure Box -->
    <p>
      If you have any questions or concerns about Bolster’s application of the Code of Conduct,
      please email <a href="mailto:support@bolsterbuilt.com">support@bolsterbuilt.com</a>.
    </p>

    <h5>United States</h5>
    <TransactionFeeTable countryCode="us"></TransactionFeeTable>

    <h5>Canada</h5>
    <TransactionFeeTable countryCode="ca"></TransactionFeeTable>

    <!-- Miscellaneous -->

    <h4>MISCELLANEOUS</h4>
    <p>
      This Merchant Agreement will be governed by and construed in accordance with the choice of law
      provisions of the Bolster Terms of Service. If any term of this Merchant Agreement is held to
      be unenforceable, the other terms will remain in full force and effect.
    </p>
  </container>
</template>

<script>
import TransactionFeeTable from '../payments/counterparty/TransactionFeeTable.vue'

export default {
  name: 'MSA',
  components: {
    TransactionFeeTable
  }
}
</script>
